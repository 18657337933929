import './Intro.css'
import Introimg from '../assets/man.jpg'
function Intro()
{
    return (
        <div className='intro-main'>
            <div className='intro-div'>
                <div className='intro-sec'>
                    <h1>Own a Tractor?</h1>
                    <ul className='intro-ul'>
                        <li>List your Tractor, Attachments or Other Implements with Haystackers</li>
                        <li>Rent them to needy farmers</li>
                        <li>Earn cash up to 50000/year or even more</li>
                    </ul>
                    <button className='intro-btn'>
                        Learn more 
                    </button>
                </div>
                <img className='intro-sec' src={Introimg} alt="" srcSet="" />
            </div>
        </div>
    );
}
export default Intro;