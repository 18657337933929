import Header from "./Header";
import "./Listing.css";
import ListItem from "../assets/listitem.jpg";
import Footer from './Footer'

function Listing() {
  return (
    <>
      <Header />
      <div className="main-list">
        <div className="list-header">
          <ul className="list-head">
            <li>All</li>
            <li>Tractor</li>
            <li>Attachment</li>
            <li>Equipment</li>
          </ul>
          <p>
            Map View<button></button>
          </p>
        </div>
        <hr />
        <section className="list-content">
          
                  <div className="list-item-main">
                      <div className="list-item-header">
                          <p>Recommended</p>
                          <p>112 result found</p>
                      </div>
                  
                  <div className="list-card">
            <div className="listcard-item">
              <div className="list-item">
                <img src={ListItem} alt="" />
                <div className="list-card-title">
                  <h3>New Holland 892</h3>
                  <p>Pull-Type Forage Harvesters</p>
                  <div>
                    <button>101 HP</button>
                    <button>101 HP</button>
                    <h4 className="price-tag">
                      $448/<span>Day</span>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="list-item">
                <img src={ListItem} alt="" />
                <div className="list-card-title">
                  <h3>New Holland 892</h3>
                  <p>Pull-Type Forage Harvesters</p>
                  <div>
                    <button>101 HP</button>
                    <button>101 HP</button>
                    <h4 className="price-tag">
                      $448/<span>Day</span>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="list-item">
                <img src={ListItem} alt="" />
                <div className="list-card-title">
                  <h3>New Holland 892</h3>
                  <p>Pull-Type Forage Harvesters</p>
                  <div>
                    <button>101 HP</button>
                    <button>101 HP</button>
                    <h4 className="price-tag">
                      $448/<span>Day</span>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="list-item">
                <img src={ListItem} alt="" />
                <div className="list-card-title">
                  <h3>New Holland 892</h3>
                  <p>Pull-Type Forage Harvesters</p>
                  <div>
                    <button>d</button>
                    <button>d</button>
                    <h4 className="price-tag">
                      $448/<span>Day</span>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="list-item">
                <img src={ListItem} alt="" />
                <div className="list-card-title">
                  <h3>New Holland 892</h3>
                  <p>Pull-Type Forage Harvesters</p>
                  <div>
                    <button>101 HP</button>
                    <button>101 HP</button>
                    <h4 className="price-tag">
                      $448/<span>Day</span>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="list-item">
                <img src={ListItem} alt="" />
                <div className="list-card-title">
                  <h3>New Holland 892</h3>
                  <p>Pull-Type Forage Harvesters</p>
                  <div>
                    <button>101 HP</button>
                    <button>101 HP</button>
                    <h4 className="price-tag">
                      $448 /<span>Day</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
                      </div>
                      <p className="list-item-header">We have more for you</p>
            </div>   
                  <div className="list-map"></div>
                  
            </section>
          </div>
          <Footer />
    </>
  );
}

export default Listing;
