import './Hero.css'
import Heroimg from '../assets/hero.jpg'

function Hero()
{
    return (
        <div>
            <div className='hero'>
                <img src={Heroimg} alt="" />
            </div>
        </div>
    );
}
export default Hero;