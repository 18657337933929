import Header from './Header'
import Hero from './Hero'
import Filter from './Filter'
import Features from './Features';
import Features2 from './Features2';
import Intro from './Intro';
import Footer from './Footer';
function Home() {
    return (
        <>
      <Header />
      <Hero />
      <Filter />
      <Features />
      <Features2 />
      <Intro />
      <Footer />
        </>
      );
}

export default Home;