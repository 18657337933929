import './App.css';
import Home from './components/Home';
import Login from './components/Login';
import Error from './components/Error';
import {BrowserRouter, Route , Routes} from 'react-router-dom'
import Signup from './components/Signup';
import Listing from './components/Listing';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='*' element={<Error />} />
          <Route path='/listing' element={<Listing />}/>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
