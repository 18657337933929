import './Features.css'
import F1 from '../assets/f-1.svg'
import F2 from '../assets/f-2.svg'
import F3 from '../assets/f-3.svg'
import F4 from '../assets/f-4.svg'

function Features()
{
    return (
        <div className='main'>
            <div className='f'>
                <div className='f-div'>
                    <img src={F1} alt="" srcSet="" />
                    <div>
                        <p>Insurance Coverage</p>
                        <p className='fl'>on Every Rental</p>
                    </div>
                </div>
                <div className='f-div'>
                    <img src={F2} alt="" srcSet="" />
                    <div>
                        <p>Top Rated Support</p>
                        <p className='fl'>in Real Time</p>
                    </div>
                </div>
                <div className='f-div'>
                    <img src={F3} alt="" srcSet="" />
                    <div>
                        <p>Verified</p>
                        <p className='fl'>Owners & Renter</p>
                    </div>
                </div>
                <div className='f-div'>
                    <img src={F4} alt="" srcSet="" />
                    <div>
                        <p>Earn money</p>
                        <p className='fl'>on your Idle Equipment</p>
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default Features