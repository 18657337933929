import "./Footer.css";
import Fb from "../assets/fb.svg";
import int from "../assets/insta.svg";
import lin from "../assets/linkd.svg";
function Footer() {
  return (
    <div className="footer">
      <div className="Item1">
        <h2>United States (English)</h2>
        <div>
          <a href="#">
            <img src={Fb} alt="" srcSet="" />
          </a>
          <a href="#">
            <img src={int} alt="" srcSet="" />
          </a>
          <a href="#">
            <img src={lin} alt="" srcSet="" />
          </a>
        </div>
        <h3>@Haystacker, Inc</h3>
        <h3 ><a href="#">Terms</a> | <a href="#">Privacy</a> | <a href="#">Sitemap</a></h3>
      </div>
      <div className="footer-con">
        <div className="Item2">
          <h3>Company</h3>
          <p><a href="#">Our Story</a></p>
          <p><a href="#">About</a></p>
          <p><a href="#">Careers</a></p>
          <p><a href="#">Press</a></p>
          <p><a href="#">Blog</a></p>
        </div>
        <div className="Item3">
          <h3>Renters</h3>
          <p><a href="#">Search Tractors</a></p>
          <p><a href="#">iOS app</a></p>
          <p><a href="#">Android app</a></p>
          <p><a href="#">Return Policy</a></p>
          <p><a href="#">FAQs</a></p>
        </div>
        <div className="Item4">
          <h3>Owners</h3>
          <p><a href="#">List Tractors</a></p>
          <p><a href="#">iOS app</a></p>
          <p><a href="#">Android app</a></p>
          <p><a href="#">Listing Policy</a></p>
          <p><a href="#">FAQs</a></p>
        </div>
        <div className="Item5">
          <h3>Support</h3>
          <p><a href="#">How it works</a></p>
          <p><a href="#">Help Center</a></p>
          <p><a href="#">Chat, Email or Call Us</a></p>
          <p><a href="#">Give Feedback</a></p>
        </div>
      </div>
    </div>
  );
}
export default Footer;
