import './Filter.css'

function Filter()
{
    return (
        <div className='filter-card'>
            <div className='filter'>
            <div>
                <ul className='filter-Header'>
                    <li className='hover-h'>All</li>
                    <li className='hover-h'>Tractor</li>
                    <li className='hover-h'>Attachment</li>
                    <li className='hover-h'>Equipment</li>
                </ul>
            </div>
            <div className='Filter-button'>
                <div>
                    <p>Location</p>
                   <p>Santa Barbara<i className="fa-thin fa-location-dot"></i></p>
                </div>
                <div>
                    <p>Date</p>
                    <p>Apr 06- Apr 20</p>
                </div>
                <div>
                    <p>Requirement</p>
                    <p>Captain Cultivator</p>
                </div>
                <button>
                    Search
                </button>
            </div>
            </div>
        </div>
    );
}

export default Filter;