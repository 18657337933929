import "./Features2.css";
import MF1 from "../assets/mf-1.svg";
import MF2 from "../assets/mf-2.svg";
import MF3 from "../assets/mf-3.svg";
import MF4 from "../assets/mf-4.svg";
import MF5 from "../assets/mf-5.svg";

function Features2() {
  return (
    <div className="mf-main">
      <h2>How It Works</h2>
      <h3>Renting a <span>Tractor, Attachment or Equipment </span>  is easy</h3>
      <div className="mf-flex">
      <div>
        <img src={MF1} alt="" srcSet="" />
        <h4>Find the perfect <br /> Tractor</h4>
      </div>
      <div>
        <img src={MF2} alt="" srcSet="" />
        <h4>Book your <br /> Tractor</h4>
      </div>
      <div>
        <img src={MF3} alt="" srcSet="" />
        <h4>Pick-up or Get <br /> it Delivered</h4>
      </div>
      <div>
        <img src={MF4} alt="" srcSet="" />
        <h4>Hit the farm!!</h4>
        </div>
        <div>
        <img src={MF5} alt="" srcSet="" />
        <h4>Return</h4>
        </div>
      </div>
    </div>
  );
}
export default Features2;
