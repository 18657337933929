import './Signup.css'
import { FcGoogle } from "react-icons/fc";
import { BsApple } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { Link } from 'react-router-dom'
import logo from "../assets/Logo.png";
function Signup() {
    return (
        <>
            <div className='sign-form'>
                <div className='sign-main'>
                <Link to='/'>
              <img src={logo} alt="logo" srcSet="" className="logo" />
              </Link>
                    <h4>Join Haystacker</h4>
                    <div className='sign-div'>
                        <input type="text" placeholder='Name' />
                        <input type="tel" placeholder='Phone Number' />
                    </div>

                    <input className='input' type="email" placeholder='Enter your Email Address' />
                        <input className='input' type="password" placeholder='Password' />

                    <input className='input' type="password" placeholder='Confirm Password' />
                    <p className='sfor'>By signing up, you are confirming you are at least 25 years old<br/> 
                    <span>and agree to the Terms and Policies of Haystackers Holdings Corporation.</span></p>
                    <button>
                    Log in
                    </button>
                    <p className='for'>Already have an account? <span>Log In</span></p>
                    <p className='lin'>Or log in using</p>
                    <div className='flex-t-sign'>
                        <li><BsApple /></li>
                        <li><FaFacebook /></li>
                        <li><FcGoogle /></li>
                    </div>
                    <hr />
                    <button>Create Account</button>
                </div>
            </div>
        </>
        );
}

export default Signup;