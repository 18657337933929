import logo from "../assets/Logo.png";
import "./Header.css";
import React, { useState } from "react";
import { Link } from 'react-router-dom'


function Header() {
  const [menu, setMenu] = useState("d-none ");
  const [icon, setIcon] = useState("ri-menu-line");
  const clickHendel = (useState) => {
    if (menu === "d-none" && icon === "ri-menu-line") {
      setMenu("menu width");
      setIcon("ri-close-line");
    } else {
      setMenu("d-none");
      setIcon("ri-menu-line");
    }
  };
  return (
    <div>
      <div className="cointainer">
        <header className="head">
          <div className="fixed flot-right">
            <Link to='/'>
              <img src={logo} alt="logo" srcSet="" className="logo" />
              </Link>
            <ul className="menu-list">
              <div>
                <li>
                  <a href="#" className="hover-h">
                    Help
                  </a>
                </li>
                <li>
                  <Link to='/Login' className="hover-h">
                    Login
                  </Link>
                </li>
                <li>
                  <Link to='/Signup' className="hover-h">
                    Singup
                  </Link>
                </li>
              </div>
              <li className="list-t">
                <a href="#">List my Tractor</a>
              </li>
            </ul>
            <li className="icon hem-menu" onClick={clickHendel}>
              <i className={icon}></i>
            </li>
          </div>
          <div className={menu}>
            <li className="menu-link-li">
              <a className="menu-link-a" href="#">Help</a>
            </li>
            <li className="menu-link-li">
              <a className="menu-link-a" href="#">Login</a>
            </li>
            <li className="menu-link-li">
              <a className="menu-link-a" href="#">Singup</a>
            </li>
          </div>
        </header>
      </div>
    </div>
  );
}
export default Header;
