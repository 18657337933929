
import './Login.css'
import { FcGoogle } from "react-icons/fc";
import { BsApple } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { Link } from 'react-router-dom'
import logo from "../assets/Logo.png";
function Login() {
    return (
        <>
            <div className='main-form'>
                <div className='login-main'>
                <Link to='/'>
              <img src={logo} alt="logo" srcSet="" className="logo" />
              </Link>
                    <h4>Log in with your email</h4>
                    <input type="email" placeholder='Enter your Email Address' />
                    <input type="password" placeholder='Password' />
                    <p className='for'>Forgot your Password?</p>
                    <button>
                    Log in
                    </button>
                    <p className='for'>Don’t have an account? <span>Sign up for free</span></p>
                    <p className='lin'>Or log in using</p>
                    <div className='flex-t-login'>
                        <li><BsApple /></li>
                        <li><FaFacebook /></li>
                        <li><FcGoogle /></li>
                    </div>
                    <hr />
                    <button>Create Account</button>
                </div>
            </div>
        </>
        );
}

export default Login;